$primary: #FFA729;
$secondary: #4AAFFF;
$black: #fff;
$white: #000;
$gray-900: #f8f9fa;
$gray-100: #212519;
$gray-200: #343a40;
$gray-800: #e9ecef;
$gray-300: #495057;
$gray-700: #dee2e6;
$gray-400: #6c757d;
$gray-600: #ced4da;
$card-cap-bg: rgba($black, .23);
$font-family-sans-serif: 'Alte DIN 1451 Mittelschrift', sans-serif;
$font-size-base: 1.1rem;
@import url('https://fonts.cdnfonts.com/css/alte-din-1451-mittelschrift-gepraegt');
@import "node_modules/bootstrap/scss/bootstrap.scss";
body {
  background-image: url('../assets/dots.981d826c.png');
  background-repeat: no-repeat;
  background-position: top -250px right -300px;
  background-clip: content-box;
}
.banner-title:after {
  content: '';
  display: block;
  height: 5px;
  width: 8.8rem;
  background-color: #4AAFFF;
}
.small-text {
  font-size: 0.3em;
}
.wb-ba {
  word-break: break-all;
}